<template>
    <div class="h:mt-8 md:mt-0" v-if="settings">
        <div class="pt-5 bg-gray-50">
          <div class="container max-w-6xl px-5 mx-auto">
            <div class="grid gap-4 md:grid-cols-2">
              <div class="self-center pb-14 h:pt-14">
                  <h4 class="text-base font-bold leading-relaxed md:text-4xl">{{ settings ? settings.strings.website.websiteHeaderSubtitle : "" }}</h4>
                  <p class="pt-5 text-lg font-light leading-normal text-gray-600 md:text-2l"><strong>{{ settings.strings.app_name }}</strong> {{ settings.strings.website.websiteFooterBrief }}</p>
                  <div class="flex flex-row mt-6 self-center" v-if="settings">
                    <a :href="settings.strings.androidDownloadLink" target="_blank"><img src="/img/play-store.png" class="h-14 md:h-20"/></a>
                    <a :href="settings.strings.iosDownloadLink" target="_blank"><img src="/img/app-store.png" class="h-14 md:h-20"/></a>
                    <a href="https://appgallery.huawei.com/app/C106440199" target="_blank"><img src="/img/app-gallery.png" class="h-14 md:h-20"/></a>
                  </div>
              </div>
              <div class="self-center">
                  <img src="/img/masapbuy.png" class="mx-auto"/>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            settings: null,
            base_url: this.$store.state.baseUrl
        }
    },
    mounted() {
        axios.get(this.base_url+'api/app/settings')
        .then((response) => {
            this.settings = response.data
        })
        .catch(error => console.log(error))

    },
}
</script>